import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FaBook, FaEdit, FaEnvelope, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ShepherdTour } from 'react-shepherd';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import pageConfig from './page.config';
import { stepsOptions, tourOptions } from './tour.config';
import { setLoading } from '../../store/actions/loadingActions';
import { fetchUsers, fetchSubordinates } from '../../services/api/users';
import history from '../../services/history';
import { useOrderByProperty } from '../../hooks/useOrderByProperty';
import { SearchAndAddContainer } from './components/searchAndAddContainer';
import Table from '../../components/table/table';
import { ThWithOrdering } from '../../components/table/thWithOrdering';
import Td from '../../components/table/td';

Users.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function Users({ currentUser }) {
    const [canAddUsers, setCanAddUsers] = useState(null);
    const [users, setUsers] = useState([]);
    const { activeFilter, filteredData, setFilteredData, orderByProperty } =
        useOrderByProperty(users, 'name', 'asc');
    const [steps, setSteps] = useState([]);
    const [ready, setReady] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            dispatch(setLoading(true));

            let data;

            if (currentUser.permissions === 'condor_admin') {
                data = await fetchUsers();
            } else {
                data = await fetchSubordinates();
                setCanAddUsers(data?.canAddUsers);
            }

            if (currentUser.permissions === 'manager') {
                const showAddPatientButtonTour = JSON.parse(
                    localStorage.getItem('showAddPatientButtonTour')
                );
                const showAppDownloadTour = JSON.parse(
                    localStorage.getItem('showAppDownloadTour')
                );

                if (
                    (showAddPatientButtonTour === null ||
                        showAddPatientButtonTour === true) &&
                    data?.users.length === 0
                ) {
                    localStorage.setItem('showAddPatientButtonTour', 'true');
                    setSteps(stepsOptions[lang].addPatientButton);
                } else {
                    localStorage.setItem('showAddPatientButtonTour', 'false');
                    if (
                        (showAppDownloadTour === null ||
                            showAppDownloadTour === true) &&
                        data?.users.length !== 0
                    ) {
                        localStorage.setItem('showAppDownloadTour', 'true');
                        setSteps(stepsOptions[lang].appDownload);
                    }
                }
            }

            if (data?.users.length === 0) {
                toast.info(pageData.noUsersText);
            }

            setUsers(data?.users);

            setReady(true);

            dispatch(setLoading(false));
        })();
    }, []);

    if (isLoading || !ready) {
        return <></>;
    }

    return (
        <>
            <ShepherdTour steps={steps} tourOptions={tourOptions}>
                <SearchAndAddContainer
                    users={users}
                    setFilteredUsers={setFilteredData}
                    canAddUsers={canAddUsers}
                    modalProps={pageData.modalProps}
                    currentUser={currentUser}
                    texts={{
                        searchByNameText: pageData.searchByNameText,
                        searchBySecondaryIdText:
                            pageData.searchBySecondaryIdText,
                        addText: pageData.addText,
                        addUserText: pageData.addUserText,
                        importUserText: pageData.importUserText,
                    }}
                />
            </ShepherdTour>

            <Row>
                <Col xs="12">
                    <Table className={styles.usersTable}>
                        <thead data-cy="users_table_head">
                            <tr>
                                <ThWithOrdering
                                    property="id"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                    data-cy="users_table_id_th"
                                >
                                    {pageData.idText}
                                </ThWithOrdering>
                                {currentUser.permissions === 'condor_admin' && (
                                    <ThWithOrdering
                                        property="company_name"
                                        activeFilterProperty={
                                            activeFilter.property
                                        }
                                        activeOrder={activeFilter.order}
                                        orderByProperty={orderByProperty}
                                        activeClassName={styles.active}
                                        clickableIconClassName={
                                            styles.clickableIcon
                                        }
                                        data-cy="users_table_company_name_th"
                                    >
                                        {pageData.companyNameText}
                                    </ThWithOrdering>
                                )}
                                <ThWithOrdering
                                    property="name"
                                    activeFilterProperty={activeFilter.property}
                                    activeOrder={activeFilter.order}
                                    orderByProperty={orderByProperty}
                                    activeClassName={styles.active}
                                    clickableIconClassName={
                                        styles.clickableIcon
                                    }
                                    data-cy="users_table_name_th"
                                >
                                    {pageData.nameText}
                                </ThWithOrdering>
                                {currentUser.permissions !== 'condor_admin' && (
                                    <ThWithOrdering
                                        property="secondary_id"
                                        activeFilterProperty={
                                            activeFilter.property
                                        }
                                        activeOrder={activeFilter.order}
                                        orderByProperty={orderByProperty}
                                        activeClassName={styles.active}
                                        clickableIconClassName={
                                            styles.clickableIcon
                                        }
                                        data-cy="users_table_secondary_id_th"
                                    >
                                        {pageData.secondaryIdText}
                                    </ThWithOrdering>
                                )}
                                <th data-cy="users_table_email_th">
                                    {pageData.emailText}
                                </th>
                                <th data-cy="users_table_active_th">
                                    {pageData.activeText}
                                </th>
                                {currentUser.permissions === 'condor_admin' && (
                                    <th>{pageData.userTypeText}</th>
                                )}
                                <th data-cy="users_table_actions_th">
                                    {pageData.actionsText}
                                </th>
                            </tr>
                        </thead>
                        <tbody data-cy="users_table_body">
                            {filteredData.map((user) => (
                                <tr
                                    key={user.id}
                                    data-cy={`users_table_tr_user_id_${user.id}`}
                                >
                                    <Td title={pageData.idText}>
                                        {user.id === null ? '' : user.id}
                                    </Td>
                                    {currentUser.permissions ===
                                        'condor_admin' && (
                                        <Td title={pageData.companyNameText}>
                                            {user.company_name === null
                                                ? ''
                                                : user.company_name}
                                        </Td>
                                    )}
                                    <Td title={pageData.nameText}>
                                        <Link
                                            to={{
                                                pathname: 'users/report',
                                                state: { entity: user },
                                            }}
                                        >
                                            {user.name === null
                                                ? ''
                                                : user.name}
                                        </Link>
                                    </Td>
                                    {currentUser.permissions !==
                                        'condor_admin' && (
                                        <Td title={pageData.secondaryIdText}>
                                            {user.secondary_id === null
                                                ? ''
                                                : user.secondary_id}
                                        </Td>
                                    )}
                                    <Td title={pageData.emailText}>
                                        {user.email === null ? '' : user.email}
                                    </Td>
                                    <Td
                                        title={pageData.activeText}
                                        className={
                                            user.active
                                                ? 'textGreen'
                                                : 'textRed'
                                        }
                                    >
                                        {user.active
                                            ? pageData.yesText
                                            : pageData.noText}
                                    </Td>
                                    {currentUser.permissions ===
                                        'condor_admin' && (
                                        <Td title={pageData.userTypeText}>
                                            {user.role_id === 1 &&
                                                pageData.patientText}
                                            {user.role_id === 2 &&
                                                pageData.managerText}
                                            {user.role_id === 3 &&
                                                pageData.condorAdminText}
                                            {user.role_id === 4 &&
                                                pageData.managerAdminText}
                                        </Td>
                                    )}
                                    <Td title={pageData.actionsText}>
                                        {currentUser.permissions !==
                                            'condor_admin' && (
                                            <Link
                                                to={{
                                                    pathname: 'users/report',
                                                    state: {
                                                        entity: user,
                                                        to: history.location
                                                            .pathname,
                                                    },
                                                }}
                                                className="btn btn-success"
                                                title={pageData.reportText}
                                                data-cy={`${user.id}_report_action_link`}
                                            >
                                                <FaBook />
                                            </Link>
                                        )}
                                        <Link
                                            to={{
                                                pathname: 'users/edit',
                                                state: {
                                                    entity: user,
                                                    to: history.location
                                                        .pathname,
                                                },
                                            }}
                                            className="btn btn-secondary ml-2"
                                            title={pageData.editText}
                                            data-cy={`${user.id}_edit_action_link`}
                                        >
                                            <FaEdit />
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: 'users/credentials',
                                                state: {
                                                    entity: user,
                                                    to: history.location
                                                        .pathname,
                                                },
                                            }}
                                            className="btn btn-info ml-2"
                                            title={pageData.credentialsText}
                                            data-cy={`${user.id}_credentials_action_link`}
                                        >
                                            <FaEnvelope />
                                        </Link>
                                        <Link
                                            to={{
                                                pathname: 'users/delete',
                                                state: {
                                                    entity: user,
                                                    to: history.location
                                                        .pathname,
                                                },
                                            }}
                                            className="btn btn-danger ml-2"
                                            title={pageData.deleteText}
                                            data-cy={`${user.id}_delete_action_link`}
                                        >
                                            <FaTrash />
                                        </Link>
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: {
            allSubscriptionsSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('Campo obrigatório'),
                lang: Yup.string().required('Campo obrigatório'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                        body_part: Yup.string().required('Campo obrigatório'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
            }),
            sleepDiarySubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('Campo obrigatório'),
                lang: Yup.string().required('Campo obrigatório'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
            }),
            lumusSubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                email: Yup.string().email('Formato inválido'),
                lang: Yup.string(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                        body_part: Yup.string().required('Campo obrigatório'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
            }),
        },
        manager: {
            allSubscriptionsSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('Campo obrigatório'),
                lang: Yup.string().required('Campo obrigatório'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                        body_part: Yup.string().required('Campo obrigatório'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
            }),
            sleepDiarySubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('Campo obrigatório'),
                lang: Yup.string().required('Campo obrigatório'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
            }),
            lumusSubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                email: Yup.string().email('Formato inválido'),
                lang: Yup.string(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                        body_part: Yup.string().required('Campo obrigatório'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Campo obrigatório'),
                    })
                ),
            }),
        },
        condor_admin: {
            managerSchema: Yup.object().shape({
                company_id: Yup.number().required('Campo obrigatório'),
                name: Yup.string().required('Campo obrigatório'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Campo obrigatório'),
                email: Yup.string()
                    .email('Formato inválido')
                    .required('Campo obrigatório'),
                lang: Yup.string().required('Campo obrigatório'),
            }),
        },
    },
    en: {
        customer_admin: {
            allSubscriptionsSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Required field'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('Required field'),
                lang: Yup.string().required('Required field'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                        body_part: Yup.string().required('Required field'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                    })
                ),
            }),
            sleepDiarySubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Required field'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('Required field'),
                lang: Yup.string().required('Required field'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
            }),
            lumusSubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Required field'),
                email: Yup.string().email('Invalid format'),
                lang: Yup.string(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                        body_part: Yup.string().required('Required field'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                    })
                ),
            }),
        },
        manager: {
            allSubscriptionsSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Required field'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('Required field'),
                lang: Yup.string().required('Required field'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                        body_part: Yup.string().required('Required field'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                    })
                ),
            }),
            sleepDiarySubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Required field'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('Required field'),
                lang: Yup.string().required('Required field'),
                trialStart: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                trialEnd: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                diary_id: Yup.number(),
            }),
            lumusSubscriptionSchema: Yup.object().shape({
                name: Yup.string().required('Required field'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Campo obrigatório'),
                active: Yup.bool().required('Required field'),
                email: Yup.string().email('Invalid format'),
                lang: Yup.string(),
                devices: Yup.array().of(
                    Yup.object().shape({
                        device_id: Yup.number()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                        body_part: Yup.string().required('Required field'),
                        received_at: Yup.string()
                            .nullable()
                            .transform((curr, orig) =>
                                orig === '' ? null : curr
                            )
                            .required('Required field'),
                    })
                ),
            }),
        },
        condor_admin: {
            managerSchema: Yup.object().shape({
                company_id: Yup.number().required('Required field'),
                name: Yup.string().required('Required field'),
                secondary_id: Yup.string()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
                role_id: Yup.number().required('Required field'),
                active: Yup.bool().required('Required field'),
                email: Yup.string()
                    .email('Invalid format')
                    .required('Required field'),
                lang: Yup.string().required('Required field'),
            }),
        },
    },
};

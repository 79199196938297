import api from '../../services/api';

export function addDevice(parms) {
    const data = api
        .post(`/devices`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchDevices() {
    const data = api
        .get('/devices', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchFreeDevices() {
    const data = api
        .get('/devices', {
            params: {
                free: true,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function editDevice(id, parms) {
    const data = api
        .put(`/devices/${id}`, { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function deleteDevice(id) {
    const data = api
        .delete(`/devices/${id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchDeviceReport(id) {
    const data = api
        .get(`/devices/report/${id}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchLogs(id) {
    const data = api
        .get(`/devices/${id}/logs`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchDeviceLogs(id, days) {
    const data = api
        .get(`/devices/${id}/logs`, {
            params: {
                type: 'device',
                days,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchSensorsLogs(id, days) {
    const data = api
        .get(`/devices/${id}/logs`, {
            params: {
                type: 'sensors',
                days,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchSystemLogs(id, days) {
    const data = api
        .get(`/devices/${id}/logs`, {
            params: {
                type: 'system',
                days,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchHistoryLogs(id, days) {
    const data = api
        .get(`/devices/${id}/logs`, {
            params: {
                type: 'history',
                days,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function activateDevice(id, active) {
    const data = api
        .patch(`/devices/${id}`, { active }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function importDevices(parms) {
    let formData = new FormData();

    formData.append('file', parms.file);

    const data = api
        .post(`/devices/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchDeviceBatteryDischarge(ids, numberOfDisconnections) {
    const params = {
        device_ids: ids,
    };

    if (numberOfDisconnections !== null) {
        params.number_of_disconnections = numberOfDisconnections;
    }

    const data = api
        .get('/devices/battery_discharge', {
            params,
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

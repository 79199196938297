export default {
    pt: {
        customer_admin: {
            chooseDevicesText:
                'Selecione dispositivos para visualizar a curva de descarga de bateria',
            devicesText: 'Dispositivos',
            numberOfDisconnectionsText: 'Número de desconexões',
            seeAllDisconnectionsText: 'Ver todas desconexões',
            viewText: 'Visualizar',
            batteryDischargeChartText: 'Curva de descarga de bateria',
            batteryVoltageChartText:
                'Os gráficos indicam a curva de descarga de bateria de cada dispositivo, dos últimos 60 dias de dados',
            noDataText: 'Não há dados!',
            voltageText: 'Tensão (V)',
            hoursText: 'Horas',
            deviceText: 'Dispositivo',
            connectionText: 'Conexão',
            disconnectionText: 'Desconexão',
            referenceCurveText: 'Curva de referência',
            batteryText: 'Bateria',
            oneWeekText: '1 semana',
            oneMonthText: '1 mês',
        },
        manager: {
            chooseDevicesText:
                'Selecione dispositivos para visualizar a curva de descarga de bateria',
            devicesText: 'Dispositivos',
            numberOfDisconnectionsText: 'Número de desconexões',
            seeAllDisconnectionsText: 'Ver todas desconexões',
            viewText: 'Visualizar',
            batteryDischargeChartText: 'Curva de descarga de bateria',
            batteryVoltageChartText:
                'Os gráficos indicam a curva de descarga de bateria de cada dispositivo, dos últimos 60 dias de dados',
            noDataText: 'Não há dados!',
            voltageText: 'Tensão (V)',
            hoursText: 'Horas',
            deviceText: 'Dispositivo',
            connectionText: 'Conexão',
            disconnectionText: 'Desconexão',
            referenceCurveText: 'Curva de referência',
            batteryText: 'Bateria',
            oneWeekText: '1 semana',
            oneMonthText: '1 mês',
        },
        condor_admin: {
            chooseDevicesText:
                'Selecione dispositivos para visualizar a curva de descarga de bateria',
            devicesText: 'Dispositivos',
            numberOfDisconnectionsText: 'Número de desconexões',
            seeAllDisconnectionsText: 'Ver todas desconexões',
            viewText: 'Visualizar',
            batteryDischargeChartText: 'Curva de descarga de bateria',
            batteryVoltageChartText:
                'Os gráficos indicam a curva de descarga de bateria de cada dispositivo, dos últimos 60 dias de dados',
            noDataText: 'Não há dados!',
            voltageText: 'Tensão (V)',
            hoursText: 'Horas',
            deviceText: 'Dispositivo',
            connectionText: 'Conexão',
            disconnectionText: 'Desconexão',
            referenceCurveText: 'Curva de referência',
            batteryText: 'Bateria',
            oneWeekText: '1 semana',
            oneMonthText: '1 mês',
        },
    },
    en: {
        customer_admin: {
            chooseDevicesText:
                'Select devices to view the battery discharge curve',
            devicesText: 'Devices',
            numberOfDisconnectionsText: 'Number of disconnections',
            seeAllDisconnectionsText: 'See all disconnections',
            viewText: 'View',
            batteryDischargeChartText: 'Battery discharge curve',
            batteryVoltageChartText:
                'The graphs indicates the battery discharge curve of each device, of the last 60 days of data',
            noDataText: 'There is no data!',
            voltageText: 'Voltage (V)',
            hoursText: 'Hours',
            deviceText: 'Device',
            connectionText: 'Connection',
            disconnectionText: 'Disconnection',
            referenceCurveText: 'Reference curve',
            batteryText: 'Battery',
            oneWeekText: '1 week',
            oneMonthText: '1 month',
        },
        manager: {
            chooseDevicesText:
                'Select devices to view the battery discharge curve',
            devicesText: 'Devices',
            numberOfDisconnectionsText: 'Number of disconnections',
            seeAllDisconnectionsText: 'See all disconnections',
            viewText: 'View',
            batteryDischargeChartText: 'Battery discharge curve',
            batteryVoltageChartText:
                'The graphs indicates the battery discharge curve of each device, of the last 60 days of data',
            noDataText: 'There is no data!',
            voltageText: 'Voltage (V)',
            hoursText: 'Hours',
            deviceText: 'Device',
            connectionText: 'Connection',
            disconnectionText: 'Disconnection',
            referenceCurveText: 'Reference curve',
            batteryText: 'Battery',
            oneWeekText: '1 week',
            oneMonthText: '1 month',
        },
        condor_admin: {
            chooseDevicesText:
                'Select devices to view the battery discharge curve',
            devicesText: 'Devices',
            numberOfDisconnectionsText: 'Number of disconnections',
            seeAllDisconnectionsText: 'See all disconnections',
            viewText: 'View',
            batteryDischargeChartText: 'Battery discharge curve',
            batteryVoltageChartText:
                'The graphs indicates the battery discharge curve of each device, of the last 60 days of data',
            noDataText: 'There is no data!',
            voltageText: 'Voltage (V)',
            hoursText: 'Hours',
            deviceText: 'Device',
            connectionText: 'Connection',
            disconnectionText: 'Disconnection',
            referenceCurveText: 'Reference curve',
            batteryText: 'Battery',
            oneWeekText: '1 week',
            oneMonthText: '1 month',
        },
    },
};

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { Button, FormGroup } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Input from '../../../../components/inputForm/inputText';
import Select from '../../../../components/inputForm/inputSelect';
import { setLoading } from '../../../../store/actions/loadingActions';
import history from '../../../../services/history';
import { addUser } from '../../../../services/api/users';
import { validateForm } from '../../../../helpers/formValidator';

AddUserForm.propTypes = {
    schemas: PropTypes.object.isRequired,
    pageData: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    roleIdOptions: PropTypes.array.isRequired,
    setRoleIdOptions: PropTypes.func.isRequired,
    diaryOptions: PropTypes.array.isRequired,
    languageOptions: PropTypes.array.isRequired,
    setLanguageOptions: PropTypes.func.isRequired,
    serialNumberOptions: PropTypes.array.isRequired,
};

export function AddUserForm({
    schemas,
    pageData,
    currentUser,
    company,
    companies,
    roleIdOptions,
    setRoleIdOptions,
    diaryOptions,
    languageOptions,
    setLanguageOptions,
    serialNumberOptions,
}) {
    const formRef = useRef(null);

    const lang = useSelector((state) => state.lang.lang);

    const [devicesFields, setDevicesFields] = useState([]);

    const dispatch = useDispatch();

    const sleepDiarySubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    function handleChangeCompany(value) {
        const tempCompany = companies.find((c) => c.id === value);

        if (tempCompany) {
            const languageOption = languageOptions.find(
                (option) =>
                    option.value === tempCompany?.descriptor?.defaultLanguage
            );

            if (languageOption) {
                formRef.current.setFieldValue('lang', {
                    label: languageOption.label,
                    value: languageOption.value,
                });
            } else {
                formRef.current.clearField('lang');
            }
        } else {
            formRef.current.clearField('lang');
        }
    }

    function removeDeviceField(index) {
        let tempDevices = [...devicesFields];

        tempDevices.splice(index, 1);

        setDevicesFields(tempDevices);
    }

    function addDeviceField() {
        setDevicesFields([...devicesFields, crypto.randomUUID()]);
    }

    async function handleSubmit(data) {
        let schema;

        if (currentUser.permissions === 'condor_admin') {
            schema = schemas.managerSchema;
        } else {
            if (sleepDiarySubscription?.active && lumusSubscription?.active) {
                schema = schemas.allSubscriptionsSchema;
            } else if (sleepDiarySubscription?.active) {
                schema = schemas.sleepDiarySubscriptionSchema;
            } else if (lumusSubscription?.active) {
                schema = schemas.lumusSubscriptionSchema;
            } else {
                schema = schemas.allSubscriptionsSchema;
            }
        }

        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            if (parsedData.diary_id === 0) {
                delete parsedData['diary_id'];
            }

            const response = await addUser(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.successText);

                history.push(history.location.state?.to || '/users');
            }
        }
    }

    useEffect(() => {
        formRef.current.reset();

        if (currentUser.permissions !== 'condor_admin') {
            const companyDefaultLanguage = languageOptions.find(
                (option) =>
                    option.value === company?.descriptor?.defaultLanguage
            );

            if (companyDefaultLanguage) {
                formRef.current.setFieldValue('lang', {
                    value: companyDefaultLanguage.value,
                    label: companyDefaultLanguage.label,
                });
            }

            if (diaryOptions.length > 0) {
                formRef.current.setFieldValue('diary_id', {
                    value: diaryOptions[0].value,
                    label: diaryOptions[0].label,
                });
            }

            formRef.current.setFieldValue('role_id', {
                value: 1,
                label: pageData.patientText,
            });
        }

        if (sleepDiarySubscription?.active) {
            formRef.current.setFieldValue('active', {
                label: pageData.yesText,
                value: true,
            });
        } else {
            formRef.current.setFieldValue('active', {
                label: pageData.noText,
                value: false,
            });
        }
    }, []);

    useEffect(() => {
        let tempLanguageOptions = [];

        let tempRoleIdOptions = [];

        if (currentUser.permissions === 'condor_admin') {
            tempLanguageOptions = [
                { value: 'pt', label: pageData.portugueseText },
                { value: 'en', label: pageData.englishText },
            ];

            tempRoleIdOptions = [
                { value: 2, label: pageData.managerText },
                { value: 3, label: pageData.condorAdminText },
            ];
        } else {
            tempLanguageOptions = [
                { value: 'pt', label: pageData.portugueseText },
                { value: 'en', label: pageData.englishText },
                { value: 'ge', label: pageData.germanyText },
            ];

            tempRoleIdOptions = [{ value: 1, label: pageData.patientText }];
        }

        const diary_id = formRef.current.getFieldValue('diary_id');

        if (diary_id !== undefined) {
            const findDiary = diaryOptions.find(
                (diary) => diary.value === diary_id
            );

            if (findDiary) {
                formRef.current.setFieldValue('diary_id', {
                    label:
                        diary_id === 0
                            ? pageData.defaultDiaryText
                            : findDiary?.label,
                    value: diary_id,
                });
            }
        }

        if (lumusSubscription?.active) {
            devicesFields.map((_, index) => {
                const body_part = formRef.current.getFieldValue(
                    `devices[${index}].body_part`
                );

                if (body_part) {
                    formRef.current.setFieldValue(
                        `devices[${index}].body_part`,
                        {
                            label:
                                body_part === 'wrist'
                                    ? pageData.wristText
                                    : pageData.necklaceText,
                            value: body_part,
                        }
                    );
                }
            });
        }

        const languageOption = tempLanguageOptions.find(
            (option) => option.value === formRef.current.getFieldValue('lang')
        );

        if (languageOption) {
            formRef.current.setFieldValue('lang', {
                label: languageOption.label,
                value: languageOption.value,
            });
        }

        const roleIdOption = tempRoleIdOptions.find(
            (option) =>
                option.value === formRef.current.getFieldValue('role_id')
        );

        if (roleIdOption) {
            formRef.current.setFieldValue('role_id', {
                label: roleIdOption.label,
                value: roleIdOption.value,
            });
        }

        if (formRef.current.getFieldValue('active') !== '') {
            formRef.current.setFieldValue('active', {
                label: formRef.current.getFieldValue('active')
                    ? pageData.yesText
                    : pageData.noText,
                value: formRef.current.getFieldValue('active'),
            });
        }

        setLanguageOptions(tempLanguageOptions);

        setRoleIdOptions(tempRoleIdOptions);
    }, [lang]);

    return (
        <Form ref={formRef} onSubmit={handleSubmit}>
            <FormGroup row>
                {currentUser.permissions === 'condor_admin' && (
                    <FormGroup className="col-xs-12 col-md-4">
                        <Select
                            name="company_id"
                            label={pageData.companyLabelText}
                            options={companies.map((company) => ({
                                value: company.id,
                                label: company.name,
                            }))}
                            onChange={(e) => handleChangeCompany(e?.value)}
                            required
                            data-cy="add_user_company_id_input"
                        />
                    </FormGroup>
                )}

                <FormGroup className="col-xs-12 col-md-5">
                    <Input
                        name="name"
                        type="text"
                        label={pageData.nameLabelText}
                        placeholder={pageData.namePlaceholderText}
                        required
                        data-cy="add_user_name_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="secondary_id"
                        type="text"
                        label={pageData.secondaryIdLabelText}
                        placeholder={pageData.secondaryIdPlaceholderText}
                        data-cy="add_user_secondary_id_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Select
                        name="role_id"
                        label={pageData.userTypeLabelText}
                        options={roleIdOptions}
                        className="role-id-sheperd"
                        required
                        isDisabled={currentUser.permissions !== 'condor_admin'}
                        data-cy="add_user_role_id_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Select
                        name="active"
                        label={pageData.activeLabelText}
                        options={[
                            { value: false, label: pageData.noText },
                            { value: true, label: pageData.yesText },
                        ]}
                        className="active-sheperd"
                        required
                        isDisabled={!sleepDiarySubscription?.active}
                        data-cy="add_user_active_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="email"
                        type="text"
                        label={pageData.emailLabelText}
                        placeholder={pageData.emailPlaceholderText}
                        required={sleepDiarySubscription?.active}
                        data-cy="add_user_email_input"
                    />
                </FormGroup>

                <FormGroup className="col-xs-12 col-md-3">
                    <Select
                        name="lang"
                        label={pageData.languageLabelText}
                        options={languageOptions}
                        className="lang-sheperd"
                        required={sleepDiarySubscription?.active}
                        data-cy="add_user_lang_input"
                    />
                </FormGroup>
            </FormGroup>

            {currentUser.permissions !== 'condor_admin' && (
                <>
                    <hr />

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span data-cy="add_user_sleep_diary_subscription_fields_text">
                                {pageData.sleepDiarySubscriptionFieldsText}
                            </span>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="trialStart"
                                type="date"
                                label={pageData.trialStartLabelText}
                                disabled={!sleepDiarySubscription?.active}
                                data-cy="add_user_trialStart_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="trialEnd"
                                type="date"
                                label={pageData.trialEndLabelText}
                                disabled={!sleepDiarySubscription?.active}
                                data-cy="add_user_trialEnd_input"
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="diary_id"
                                label={pageData.diaryIdLabelText}
                                options={diaryOptions}
                                className="diary-sheperd"
                                isDisabled={!sleepDiarySubscription?.active}
                                data-cy="add_user_diary_id_input"
                            />
                        </FormGroup>
                    </FormGroup>

                    <hr />

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span data-cy="add_user_lumus_subscription_fields_text">
                                {pageData.lumusSubscriptionFieldsText}
                            </span>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span data-cy="add_user_devices_text">
                                {pageData.devicesText}
                            </span>
                        </FormGroup>
                    </FormGroup>

                    {devicesFields.map((uuid, index) => (
                        <FormGroup row key={uuid}>
                            <Scope path={`devices[${index}]`}>
                                <FormGroup className="col-xs-12 col-md-2">
                                    <Select
                                        name="device_id"
                                        label={pageData.serialNumberText}
                                        options={serialNumberOptions}
                                        className={`add_user_device_id_input_${index}`}
                                        required
                                        isDisabled={!lumusSubscription?.active}
                                        data-cy={`add_user_device_id_input_${index}`}
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Select
                                        name="body_part"
                                        label={pageData.bodyPartText}
                                        options={[
                                            {
                                                value: 'wrist',
                                                label: pageData.wristText,
                                            },
                                            {
                                                value: 'necklace',
                                                label: pageData.necklaceText,
                                            },
                                        ]}
                                        className={`add_user_body_part_input_${index}`}
                                        required
                                        isDisabled={!lumusSubscription?.active}
                                        data-cy={`add_user_body_part_input_${index}`}
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="received_at"
                                        type="date"
                                        label={pageData.receivedAtText}
                                        required
                                        disabled={!lumusSubscription?.active}
                                        data-cy={`add_user_received_at_input_${index}`}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <div className="col-xs-12 col-md-12 text-left d-flex align-items-end h-100">
                                        <Button
                                            color="danger"
                                            type="button"
                                            onClick={() =>
                                                removeDeviceField(index)
                                            }
                                            title={pageData.deleteDeviceText}
                                            data-cy={`add_user_delete_device_button_${index}`}
                                        >
                                            <FaTrash />
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Scope>
                        </FormGroup>
                    ))}

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                type="button"
                                onClick={addDeviceField}
                                title={pageData.addDeviceText}
                                data-cy="add_user_add_device_button"
                                disabled={!lumusSubscription?.active}
                            >
                                {pageData.addDeviceText}
                            </Button>
                        </div>
                    </FormGroup>
                </>
            )}

            <hr />

            <FormGroup row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Button
                        color="primary"
                        className="mr-2"
                        type="submit"
                        data-cy="add_user_add_button"
                    >
                        {pageData.addText}
                    </Button>

                    <Link
                        to={'/users'}
                        className="btn btn-secondary"
                        data-cy="add_user_cancel_link"
                    >
                        {pageData.cancelText}
                    </Link>
                </div>
            </FormGroup>
        </Form>
    );
}

export default {
    pt: {
        customer_admin: {
            searchByNameText: 'Procurar paciente por nome',
            searchBySecondaryIdText: 'Procurar paciente por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar paciente',
            idText: 'ID',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            actionsText: 'Ações',
            reportText: 'Relatório',
            editText: 'Editar',
            credentialsText: 'Credenciais',
            deleteText: 'Excluir',
            yesText: 'Sim',
            noText: 'Não',
            noUsersText: 'Você não possui pacientes cadastrados!',
            importUserText: 'Importar pacientes',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Aumente seu plano!',
                text: `Sua empresa atingiu o número máximo de pacientes ativos do plano!
                Para conseguir adicionar mais pacientes, inative pacientes ou
                faça o upgrade do plano!`,
                hasPrimaryButton: false,
                primaryButtonHasLink: false,
                primaryButtonPathName: '',
                primaryButtonText: 'Alterar plano',
                hasSecondaryButton: true,
                secondaryButtonHasLink: false,
                secondaryButtonPathName: '',
                secondaryButtonText: 'Fechar',
            },
        },
        manager: {
            searchByNameText: 'Procurar paciente por nome',
            searchBySecondaryIdText: 'Procurar paciente por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar paciente',
            idText: 'ID',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            actionsText: 'Ações',
            reportText: 'Relatório',
            editText: 'Editar',
            credentialsText: 'Credenciais',
            deleteText: 'Excluir',
            yesText: 'Sim',
            noText: 'Não',
            noUsersText: 'Você não possui pacientes cadastrados!',
            importUserText: 'Importar pacientes',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Aumente seu plano!',
                text: `Sua empresa atingiu o número máximo de pacientes ativos do plano!
                Para conseguir adicionar mais pacientes, inative pacientes ou
                faça o upgrade do plano!`,
                hasPrimaryButton: true,
                primaryButtonHasLink: true,
                primaryButtonPathName: 'change-plan',
                primaryButtonText: 'Alterar plano',
                hasSecondaryButton: true,
                secondaryButtonHasLink: true,
                secondaryButtonPathName: 'users/add',
                secondaryButtonText: 'Adicionar Gestor',
            },
        },
        condor_admin: {
            searchByNameText: 'Procurar usuário por nome',
            searchBySecondaryIdText: 'Procurar usuário por id secundário',
            addText: 'Adicionar',
            addUserText: 'Adicionar usuário',
            idText: 'ID',
            companyNameText: 'Empresa',
            nameText: 'Nome',
            secondaryIdText: 'ID Secundário',
            emailText: 'e-Mail',
            activeText: 'Ativo',
            userTypeText: 'Tipo de Usuário',
            actionsText: 'Ações',
            editText: 'Editar',
            credentialsText: 'Credenciais',
            deleteText: 'Excluir',
            yesText: 'Sim',
            noText: 'Não',
            patientText: 'Paciente',
            managerText: 'Gestor',
            condorAdminText: 'Condor Admin',
            managerAdminText: 'Gestor Admin',
            noUsersText: 'Você não possui clientes cadastrados!',
            modalProps: {},
        },
    },
    en: {
        customer_admin: {
            searchByNameText: 'Search patient by name',
            searchBySecondaryIdText: 'Search patient by secondary ID',
            addText: 'Add',
            addUserText: 'Add patient',
            idText: 'ID',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            actionsText: 'Actions',
            reportText: 'Report',
            editText: 'Edit',
            credentialsText: 'Credentials',
            deleteText: 'Delete',
            yesText: 'Yes',
            noText: 'No',
            noUsersText: `You dont't have registered patients!`,
            importUserText: 'Import patients',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Upgrade your plan!',
                text: `Your company has reached the maximum number of active patients allowed in your plan!
                To be able to add more patients, inactivate patients or
                upgrade your plan!`,
                hasPrimaryButton: false,
                primaryButtonHasLink: false,
                primaryButtonPathName: '',
                primaryButtonText: 'Change Plan',
                hasSecondaryButton: true,
                secondaryButtonHasLink: false,
                secondaryButtonPathName: '',
                secondaryButtonText: 'Close',
            },
        },
        manager: {
            searchByNameText: 'Search patient by name',
            searchBySecondaryIdText: 'Search patient by secondary ID',
            addText: 'Add',
            addUserText: 'Add patient',
            idText: 'ID',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            actionsText: 'Actions',
            reportText: 'Report',
            editText: 'Edit',
            credentialsText: 'Credentials',
            deleteText: 'Delete',
            yesText: 'Yes',
            noText: 'No',
            noUsersText: `You dont't have registered patients!`,
            importUserText: 'Import patients',
            modalProps: {
                className: 'btn btn-primary ml-2',
                title: 'Upgrade your plan!',
                text: `Your company has reached the maximum number of active patients allowed in your plan!
                To be able to add more patients, inactivate patients or
                upgrade your plan!`,
                hasPrimaryButton: true,
                primaryButtonHasLink: true,
                primaryButtonPathName: 'change-plan',
                primaryButtonText: 'Change Plan',
                hasSecondaryButton: true,
                secondaryButtonHasLink: true,
                secondaryButtonPathName: 'users/add',
                secondaryButtonText: 'Add Manager',
            },
        },
        condor_admin: {
            searchByNameText: 'Search user by name',
            searchBySecondaryIdText: 'Search user by secondary ID',
            addText: 'Add',
            addUserText: 'Add user',
            idText: 'ID',
            companyNameText: 'Company',
            nameText: 'Name',
            secondaryIdText: 'Secondary ID',
            emailText: 'e-Mail',
            activeText: 'Active',
            userTypeText: 'User Type',
            actionsText: 'Actions',
            editText: 'Edit',
            credentialsText: 'Credentials',
            deleteText: 'Delete',
            yesText: 'Yes',
            noText: 'No',
            patientText: 'Patient',
            managerText: 'Manager',
            condorAdminText: 'Condor Admin',
            managerAdminText: 'Manager Admin',
            noUsersText: `You dont't have registered customers!`,
            modalProps: {},
        },
    },
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { ShepherdTour } from 'react-shepherd';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { stepsOptions, tourOptions } from './tour.config';
import history from '../../../services/history';
import { setLoading } from '../../../store/actions/loadingActions';
import { fetchDiaries } from '../../../services/api/diaries';
import { fetchCompany } from '../../../services/api/account';
import { fetchCompanies } from '../../../services/api/companies';
import { fetchFreeDevices } from '../../../services/api/devices';
import { AddUserForm } from './components/form';

AddUser.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function AddUser({ currentUser }) {
    const [company, setCompany] = useState({});
    const [diaries, setDiaries] = useState([]);
    const [devices, setDevices] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [roleIdOptions, setRoleIdOptions] = useState([]);
    const [diaryOptions, setDiaryOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [serialNumberOptions, setSerialNumberOptions] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);
    const isLoading = useSelector((state) => state.loading.isLoading);

    const pageData = pageConfig[lang][currentUser.permissions];

    const {
        allSubscriptionsSchema,
        sleepDiarySubscriptionSchema,
        lumusSubscriptionSchema,
        managerSchema,
    } = schemaConfig[lang][currentUser.permissions];

    useEffect(() => {
        (async function () {
            if (
                currentUser.permissions === 'manager' ||
                currentUser.permissions === 'customer_admin'
            ) {
                if (!history.location.state?.canAddUsers) {
                    toast.warning(pageData.warningText);

                    history.push('/users');

                    return;
                }

                dispatch(setLoading(true));
                setPageLoading(true);

                setLanguageOptions([
                    { value: 'pt', label: pageData.portugueseText },
                    { value: 'en', label: pageData.englishText },
                    {
                        value: 'ge',
                        label: pageData.germanyText,
                    },
                ]);

                setRoleIdOptions([{ value: 1, label: pageData.patientText }]);

                const companyData = await fetchCompany();

                if (companyData?.company) {
                    setCompany(companyData.company);
                }

                const diariesData = await fetchDiaries();

                if (diariesData?.diaries) {
                    setDiaries(diariesData.diaries);

                    const defaultDiaryOption = {
                        value: 0,
                        label: pageData.defaultDiaryText,
                    };

                    setDiaryOptions([
                        defaultDiaryOption,
                        ...diariesData.diaries.map((diary) => {
                            return {
                                value: diary.id,
                                label: diary.name,
                            };
                        }),
                    ]);
                }

                const devicesData = await fetchFreeDevices();

                if (devicesData?.devices) {
                    setDevices(devicesData.devices);

                    setSerialNumberOptions(
                        devicesData.devices.map((device) => {
                            return {
                                value: device.id,
                                label: device.serial_no,
                            };
                        })
                    );
                }

                dispatch(setLoading(false));
                setPageLoading(false);
            }

            if (currentUser.permissions === 'condor_admin') {
                dispatch(setLoading(true));
                setPageLoading(true);

                const companiesData = await fetchCompanies();

                if (companiesData?.companies) {
                    setCompanies(companiesData.companies);
                }

                setLanguageOptions([
                    { value: 'pt', label: pageData.portugueseText },
                    { value: 'en', label: pageData.englishText },
                ]);

                setRoleIdOptions([
                    { value: 2, label: pageData.managerText },
                    { value: 3, label: pageData.condorAdminText },
                ]);

                dispatch(setLoading(false));
                setPageLoading(false);
            }
        })();
    }, []);

    if (pageLoading) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <ShepherdTour
                    steps={stepsOptions[lang].addPatient}
                    tourOptions={tourOptions}
                >
                    <AddUserForm
                        schemas={{
                            allSubscriptionsSchema,
                            sleepDiarySubscriptionSchema,
                            lumusSubscriptionSchema,
                            managerSchema,
                        }}
                        pageData={pageData}
                        currentUser={currentUser}
                        company={company}
                        companies={companies}
                        roleIdOptions={roleIdOptions}
                        setRoleIdOptions={setRoleIdOptions}
                        diaryOptions={diaryOptions}
                        languageOptions={languageOptions}
                        setLanguageOptions={setLanguageOptions}
                        serialNumberOptions={serialNumberOptions}
                    />
                </ShepherdTour>
            </CardBody>
            <CardFooter>
                <p data-cy="add_user_required_fields_text">
                    <b style={{ color: 'darkred' }}>*</b>{' '}
                    {pageData.requiredFieldText}
                </p>
            </CardFooter>
        </Card>
    );
}
